exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "home": {
      return `/`;
    }

    case "information": {
      return `/${doc.uid}/`;
    }

    case "text": {
      return `/texts/${doc.uid}/`;
    }

    case "exhibition": {
      return `/exhibitions/${doc.uid}/`;
    }

    case "work": {
      return `/works/${doc.uid}/`;
    }
  }
};
