import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import { AnimatePresence } from "framer-motion";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Templates
import Exhibition from "./src/templates/exhibition";
import Text from "./src/templates/text";
import Work from "./src/templates/work";

// Pages
import Index from "./src/pages/index";
import Information from "./src/pages/information";

// Transitions
import {} from "framer-motion";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          exhibition: Exhibition,
          text: Text,
          work: Work,
          index: Index,
          information: Information,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // transition duration from `layout.js` * 1000 to get time in ms
  // * 2 for exit + enter animation
  const TRANSITION_DELAY = 0.35 * 1000 * 2;

  // if it's a "normal" route
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
  }

  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0];

    window.setTimeout(
      () => window.scrollTo(...savedPosition),
      TRANSITION_DELAY
    );
  }

  return false;
};
