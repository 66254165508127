import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10000;
  background-color: #fff;

  opacity: ${(props) => props.opacity};
  transition: 350ms opacity ease;

  & .content-container,
  & .gatsby-image-wrapper {
    height: 100vh !important;
    width: 100vw !important;

    & img {
      height: 100vh !important;
      width: 100vw !important;
      object-fit: cover;

      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`;

export const Splash = ({ showSplash, setShowSplash }) => {
  const data = useStaticQuery(graphql`
    {
      prismicSplash {
        data {
          image {
            gatsbyImageData(layout: FULL_WIDTH, breakpoints: [2000, 3000, 4000])
            dimensions {
              height
              width
            }
            alt
          }
        }
      }
    }
  `);

  return (
    <SplashContainer
      opacity={showSplash ? 1 : 0}
      onClick={() => {
        setShowSplash(false);
      }}
    >
      <div className="content-container">
        <div className="content">
          {data.prismicSplash.data.image.gatsbyImageData !== null && (
            <GatsbyImage
              image={data.prismicSplash.data.image.gatsbyImageData}
              alt={
                data.prismicSplash.data.image.alt !== null
                  ? data.prismicSplash.data.image.alt
                  : "Splash"
              }
            />
          )}
        </div>
      </div>
    </SplashContainer>
  );
};
