import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";

// Layout
import { Page } from "../components/containers/page";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Information = ({ data }) => {
  const content = data.prismicInformation.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <div
          key={`single_text_${index}_${content.id}`}
          className="single-text-container overview-grid bottom-border"
        >
          <div className="column left">
            <PrismicRichText field={content.primary.module_title.richText} />
          </div>

          <div className="column right">
            <PrismicRichText field={content.primary.module_text.richText} />
          </div>
        </div>
      );
    }

    if (content.slice_type === "text_with_links") {
      return (
        <div
          key={`single_text_with_links${index}_${content.id}`}
          className="single-text-with-links-container overview-grid bottom-border"
        >
          <div className="column left">
            <PrismicRichText field={content.primary.module_title.richText} />
          </div>

          <div className="column right">
            <PrismicRichText field={content.primary.module_text.richText} />
          </div>
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={`Information and Contact`}
        seoImage={null}
        seoText={null}
      />

      <Page>
        <div className="page-grid">
          <div className="spacer" />

          <div className="content-container">
            {content}

            <div className="credits-container overview-grid bottom-border">
              <div className="column left">
                <h2>Credits</h2>
              </div>
              <div className="column right">
                <p>
                  Design:{" "}
                  <a href="https://georgehaughton.co.uk/" target="_blank">
                    George Haughton
                  </a>
                </p>
                <p>
                  Development:{" "}
                  <a href="https://kieranstartup.co.uk/" target="_blank">
                    Kieran Startup
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="spacer" />
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Information);

export const query = graphql`
  {
    prismicInformation {
      _previewable
      url
      id
      data {
        body {
          ... on PrismicInformationDataBodyText {
            id
            slice_type
            primary {
              module_title {
                richText
              }
              module_text {
                richText
              }
            }
          }
          ... on PrismicInformationDataBodyTextWithLinks {
            id
            slice_type
            primary {
              module_title {
                richText
              }
              module_text {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
