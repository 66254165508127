import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: grid;
  grid-template-columns: 1fr minmax(600px, 35%) 1fr;

  background-color: #fff;

  z-index: 200;
  padding: 40px 0 0 0;

  transition: 300ms opacity ease;

  & nav {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    border-bottom: 1px solid #000;

    padding: 0 0 12px 0;

    & h3 {
      grid-column: 2 / 6;
    }

    & ol {
      display: flex;
      flex-direction: row;
      padding: 80px 0 0 0;

      grid-column: 2 / 6;

      & li {
        margin: 0 20px 0 0;

        &:last-of-type {
          margin: 0;
        }

        & a {
          color: lightgray;
          transition: 300ms color ease;

          &:hover,
          &.current {
            color: #000;
          }
        }
      }

      @media (max-height: 700px) {
        padding: 40px 0 0 0;
      }
    }
  }

  // Mobile
  @media (max-width: 700px) {
    display: block;
    grid-template-columns: unset;

    padding: 30px 30px 0 30px;

    & nav {
      display: block;
      grid-template-columns: unset;

      & ol {
        display: block;
        padding: 2em 0 0 0;

        grid-column: unset;

        & li {
          margin: 0;
        }
      }
    }
  }
`;

export const Header = ({ location }) => {
  if (location.includes("/works/")) return null;

  return (
    <HeaderWrapper>
      <div className="spacer" />

      <nav>
        <h3>
          <Link to={`/`}>Lewis Brander</Link>
        </h3>

        <ol>
          <li>
            <Link to={`/`} activeClassName="current">
              Works
            </Link>
          </li>
          <li>
            <Link
              to={`/exhibitions/`}
              activeClassName="current"
              partiallyActive
            >
              Exhibitions
            </Link>
          </li>
          <li>
            <Link to={`/texts/`} activeClassName="current" partiallyActive>
              Texts
            </Link>
          </li>
          <li>
            <Link
              to={`/information/`}
              activeClassName="current"
              partiallyActive
            >
              Information and Contact
            </Link>
          </li>
        </ol>
      </nav>

      <div className="spacer" />
    </HeaderWrapper>
  );
};
