import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { useKeyPressEvent, useWindowSize } from "react-use";
import { PrismicRichText } from "@prismicio/react";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  padding: 41px 0 39px 0;

  width: 100%;

  height: calc(
    ${(props) => props.height}px - ${(props) => props.captionHeight}px
  );

  z-index: 10;

  & #carousel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    flex: 1 0 0;

    height: 100%;
  }

  & .react-slideshow-container,
  & .react-slideshow-wrapper,
  & .react-slideshow-fadezoom-wrapper,
  & .react-slideshow-fadezoom-images-wrap,
  & .images-wrap,
  & .images-wrap > div,
  & .slide,
  & .works-images,
  & .image-slide,
  & .video-slide {
    position: relative;

    width: 100%;
    height: 100%;
    max-height: calc(
      ${(props) => props.height}px - ${(props) => props.captionHeight}px - 60px -
        20px
    );
  }

  & .image-slide > div {
    height: calc(
      ${(props) => props.height}px - ${(props) => props.captionHeight}px - 60px -
        20px
    );
  }

  & .image-container {
    padding: 0 40px;
  }

  & .gatsby-image-wrapper {
    height: 100%;
  }

  & img {
    width: 100%;
    height: 100%;

    height: calc(
      ${(props) => props.height}px - ${(props) => props.captionHeight}px - 60px -
        20px - 22px
    );

    padding: 90px 0;

    object-fit: contain !important;

    @media (max-width: 860px) {
      padding: 0;
      object-fit: cover !important;
    }
  }

  & .react-slideshow-slide-images-wrap {
    align-items: center;
  }

  // 700px media query
  @media (max-width: 769px) {
    height: calc(
      ${(props) => props.height}px - ${(props) => props.captionHeight}px - 22px
    );

    & .image-container {
      padding: 0;
    }
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 60px;
  bottom: 20px;
  width: 50%;

  z-index: 10;

  &.previous {
    left: 0;

    cursor: url("/icons/previous@1x.png") 0 0, url("/icons/previous.cur") 0 0,
      w-resize !important;
    cursor: -webkit-image-set(
          url("/icons/previous@1x.png") 1x,
          url("/icons/previous@2x.png") 2x
        )
        0 0,
      w-resize !important;
  }

  &.next {
    right: 0;

    cursor: url("/icons/next@1x.png") 0 0, url("/icons/next.cur") 0 0, e-resize !important;
    cursor: -webkit-image-set(
          url("/icons/next@1x.png") 1x,
          url("/icons/next@2x.png") 2x
        )
        0 0,
      e-resize !important;
  }

  & svg {
    & path {
      transition: 250ms stroke ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          stroke: #dbaf96;
        }
      }
    }
  }
`;

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;

  padding: 0 30px 22px 30px;
  /* min-height: 40px; */

  h2,
  p,
  button {
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.15px;

    margin: 0 5px;
  }

  h2,
  p {
    text-align: center;
  }

  & br {
    display: none;
  }

  button {
    transition: 300ms color ease;
    color: #a0a0a0;

    &:hover {
      color: #000;
    }
  }

  // 700px media query
  @media (max-width: 700px) {
    min-height: 80px;

    h2,
    p {
      width: 100%;
    }

    h2,
    p,
    button {
      font-size: 15px;
    }

    /* & em {
      display: block;
    } */
  }
`;

export const Gallery = ({
  media,
  windowHeight,
  type,
  setActiveModalId,
  defaultIndex,
  setDefaultIndex,
  captions,
}) => {
  const { width } = useWindowSize();
  const [currentSlide, setCurrentSlide] = useState(defaultIndex);

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, [ref, width]);

  // Slideshow Reference
  const slideRef = useRef();

  // Keyboard navigation events for slideshow
  const goBack = () => {
    if (slideRef.current !== undefined && media.length > 1) {
      slideRef.current.goBack();
    }
  };

  const goNext = () => {
    if (slideRef.current !== undefined && media.length > 1) {
      slideRef.current.goNext();
    }
  };
  useKeyPressEvent("ArrowRight", goNext);
  useKeyPressEvent("ArrowLeft", goBack);

  return (
    <>
      <GalleryContainer
        height={windowHeight}
        id="artworks-container"
        captionHeight={height}
      >
        {media.length > 1 ? (
          <>
            {media.length > 1 && (
              <Arrow
                aria-label="Previous Slide"
                className={`previous arrow`}
                type="button"
                onClick={() => {
                  slideRef.current.goBack();
                }}
              />
            )}

            <div id="carousel-container">
              <Fade
                ref={slideRef}
                arrows={false}
                transitionDuration={2000}
                pauseOnHover={false}
                autoplay={false}
                infinite={true}
                canSwipe={media.length > 1 ? true : false}
                defaultIndex={defaultIndex}
                onChange={(previous, next) => updateCurrentSlide(next)}
                easing={"cubic-out"}
              >
                {media}
              </Fade>
            </div>

            {media.length > 1 && (
              <Arrow
                aria-label="Next Slide"
                className={`next arrow`}
                type="button"
                onClick={() => {
                  slideRef.current.goNext();
                }}
              />
            )}
          </>
        ) : (
          <div>{media}</div>
        )}
      </GalleryContainer>

      <CaptionContainer ref={ref}>
        <PrismicRichText field={captions[currentSlide]} />

        <button
          type="button"
          onClick={() => {
            setActiveModalId(null);
            setDefaultIndex(0);
          }}
        >
          Close
        </button>
      </CaptionContainer>
    </>
  );
};
