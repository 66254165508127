import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";

// Layout
import { Page } from "../components/containers/page";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Index = ({ data, location }) => {
  useEffect(() => {
    if (location !== undefined && location !== null) {
      if (location.state !== undefined && location.state !== null) {
        if (
          location.state.section !== undefined &&
          location.state.section !== null
        ) {
          const scrollToElement = setTimeout(() => {
            const dims = document
              .querySelector(`#work-id-${location.state.section}`)
              .getBoundingClientRect().top;

            window.scrollTo(0, dims - 178 - 17);
          }, 750);
          return () => clearTimeout(scrollToElement);
        }
      }
    }
  }, [location]);

  const content = data.allPrismicWork.nodes.map((work, index) => (
    <li
      key={`single_work_${index}_${work.id}`}
      className="single-work overview-grid list-item"
      id={`work-id-${work.id}`}
    >
      <div className="column left">
        <p>{work.data.date}</p>
      </div>

      <div className="column right">
        <Link to={work.url} state={{ section: work.id }}>
          <PrismicRichText
            field={work.data.title_of_work.richText}
            components={{
              heading1: ({ children }) => (
                <h1 className="italic">{children}</h1>
              ),
            }}
          />
          <p>
            {work.data.medium}, {work.data.dimensions}
          </p>
        </Link>
      </div>
    </li>
  ));

  return (
    <>
      <PageSeo seoTitle={`Works`} seoImage={null} seoText={null} />
      <Page>
        <div className="page-grid">
          <div className="spacer" />

          <div className="content-container">
            <ol>{content}</ol>
          </div>

          <div className="spacer" />
        </div>

        <div className="page-bottom-border">
          <div className="border" />
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  query Works {
    allPrismicWork(sort: { fields: data___date, order: DESC }) {
      nodes {
        url
        id
        data {
          title_of_work {
            richText
          }
          medium
          dimensions
          framed_dimensions
          date
        }
      }
    }
  }
`;
