import React, { useState, useEffect } from "react";
import { graphql, Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";

// Layout
import { Page } from "../components/containers/page";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Components
import { Gallery } from "../components/images/gallery";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

Modal.setAppElement("#___gatsby");

const Text = ({ data, location }) => {
  const [activeModalId, setActiveModalId] = useState(null);
  const [defaultIndex, setDefaultIndex] = useState(0);

  const [backButton, setBackButton] = useState(null);

  useEffect(() => {
    if (location !== undefined && location !== null) {
      if (location.state !== undefined && location.state !== null) {
        if (
          location.state.section !== undefined &&
          location.state.section !== null
        ) {
          setBackButton(location.state.section);
        }
      }
    }
  }, [location, setBackButton]);

  // Window Height
  const height = use100vh();

  const content = data.prismicText.data.body.map((slice, index) => {
    if (slice.slice_type === `gallery`) {
      const media = slice.items.map((image, index) => {
        return (
          <div
            className="slide"
            key={`single_exhibition_${index}_${image.image.alt}`}
          >
            <GatsbyImage
              image={image.image.gatsbyImageData}
              alt={image.image.alt}
            />
          </div>
        );
      });

      const captions = slice.items
        .filter((caption) => caption.caption !== null)
        .map((caption, index) => caption.caption.richText);

      return (
        <div className="gallery-container module">
          <div
            className="first-gallery-image"
            onClick={() => setActiveModalId(slice.id)}
          >
            {media[0]}
          </div>

          {slice.id === activeModalId && (
            <Modal
              isOpen={slice.id === activeModalId}
              portalClassName={`ReactModalPortal-artworks-lightbox`}
              className="lightbox-modal"
              contentLabel={`Artworks Lightbox`}
              shouldFocusAfterRender={false}
            >
              <Gallery
                media={media}
                windowHeight={height}
                type={`gallery`}
                setActiveModalId={setActiveModalId}
                defaultIndex={0}
                setDefaultIndex={setDefaultIndex}
                captions={captions}
              />
            </Modal>
          )}
        </div>
      );
    }

    if (slice.slice_type === `text`) {
      return (
        <div
          key={`single_exhibition_${index}_${slice.id}`}
          className="text-container overview-grid module"
        >
          <div className="column left">
            <PrismicRichText field={slice.primary.section_title.richText} />

            {backButton !== null ? (
              <button
                onClick={() => navigate(-1)}
                className="back-button desktop"
              >
                Back
              </button>
            ) : (
              <Link to={`/texts/`} className="back-button desktop">
                Back
              </Link>
            )}
          </div>

          <div className="column right">
            <div className="header-text-container">
              <PrismicRichText field={slice.primary.header_text.richText} />
            </div>

            <div className="body-text-container text-page">
              <PrismicRichText field={slice.primary.text.richText} />
            </div>
          </div>
        </div>
      );
    }
    if (slice.slice_type === `works_images`) {
      return (
        <div
          className="text-images-container module"
          key={`single_works_images_${index}_${slice.id}`}
        >
          <GatsbyImage
            image={slice.primary.image.gatsbyImageData}
            alt={slice.primary.image.alt}
          />
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={data.prismicText.data.title.text}
        seoImage={null}
        seoText={null}
      />

      <Page height={height}>
        <div className="page-grid">
          <div className="spacer" />

          <div className="content-container">
            {content}

            {backButton !== null ? (
              <button
                onClick={() => navigate(-1)}
                className="back-button mobile"
              >
                Back
              </button>
            ) : (
              <Link to={`/texts/`} className="back-button mobile">
                Back
              </Link>
            )}

            <div className="bottom-border" />
          </div>

          <div className="spacer" />
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Text);

export const query = graphql`
  query Text($id: String) {
    prismicText(id: { eq: $id }) {
      _previewable
      url
      data {
        title {
          richText
          text
        }
        format
        body {
          ... on PrismicTextDataBodyGallery {
            id
            slice_type
            items {
              image {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  breakpoints: [1000, 1500, 2000, 3000, 4000]
                )
                alt
              }
              caption {
                richText
              }
            }
          }
          ... on PrismicTextDataBodyText {
            id
            slice_type
            primary {
              header_text {
                richText
              }
              section_title {
                richText
              }
              text {
                richText
              }
            }
          }
          ... on PrismicTextDataBodyWorksImages {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(
                  layout: FULL_WIDTH
                  breakpoints: [1000, 1500, 2000, 3000, 4000]
                )
              }
            }
          }
        }
      }
    }
  }
`;
