import React, { useEffect } from "react";

// Components
import { Header } from "./header";

export const App = ({ location, data, children }) => {
  useEffect(() => {
    document.body.classList.remove(`hover-active`);
  }, [location]);

  return (
    <>
      <Header location={location} />
      <main>{children}</main>
    </>
  );
};
