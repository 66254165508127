import React from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  margin: 196px 0 40px 0;
  padding: 0 20px;

  overflow-x: hidden;

  @media (max-height: 700px) {
    margin: 156px 0 40px 0;
  }

  @media (max-width: 700px) {
    margin: 192px 0px 40px 0;
  }

  .page-grid {
    display: grid;
    grid-template-columns: 1fr minmax(600px, 35vw) 1fr;

    @media (max-width: 700px) {
      display: block;
      grid-template-columns: unset;
    }
  }

  .bottom-border {
    border-bottom: 1px solid #000;
    width: 100%;

    align-self: flex-end;
  }

  .module {
    margin: 0 0 16px 0;

    & h2.section-title {
      margin: 0 0 4px 0;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;

    position: relative;
    z-index: 10;

    ol {
      & li {
        transition: 300ms opacity ease;

        &:hover {
          opacity: 0.2;
        }
      }
    }

    .overview-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      margin: 0 0 16px 0;
      width: 100%;

      .column {
        &.left {
          grid-column: 1;

          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & button.back-button,
          & a.back-button {
            transition: 300ms color ease;
            color: #a0a0a0;

            &:hover {
              color: #000;
            }

            @media (max-width: 700px) {
              display: none;
            }
          }
        }

        &.right {
          grid-column: 2 / 6;

          & .header-text-container {
            & p {
              margin: 0;

              &:last-of-type {
                margin-bottom: 1em;
              }
            }
          }

          & .body-text-container {
            & p {
              margin: 0;
              text-indent: 40px;

              &:first-of-type {
                text-indent: 0;
              }
              /* &:first-of-type {
                margin-top: 0;
              } */
            }

            &.text-page {
              & p {
                margin: 1em 0;
                text-indent: 0;

                &:first-of-type {
                  margin-top: 0;
                }
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }

          & p {
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      &.list-item {
        & p {
          margin: 0;
        }
      }

      @media (max-width: 700px) {
        display: block;
        grid-template-columns: unset;

        & button.back-button.desktop,
        & a.back-button.desktop {
          display: none;
        }
      }
    }

    .single-text-container {
      padding: 0 0 12px 0;
      /* 0 0 12px 0; */

      & p {
        margin: 0;

        & a {
          transition: 300ms color ease;
          color: #a0a0a0;

          &:hover {
            color: #000;
          }
        }
      }

      & .column {
        &.right {
          & p {
            text-indent: 40px;

            &:first-of-type {
              text-indent: 0;
            }
          }
        }
      }
    }

    .single-text-with-links-container {
      padding: 0 0 12px 0;
      /* 0px 0px 1em; */

      & p {
        margin: 5px 0;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        & a {
          transition: 300ms color ease;
          color: #a0a0a0;

          &:hover {
            color: #000;
          }
        }
      }
    }

    .credits-container {
      padding: 5em 0 14px 0;

      & h2,
      & p {
        font-size: 12px;
        line-height: 135%;

        margin: 0;
      }

      & a {
        transition: 300ms color ease;
        color: #000;

        &:hover {
          color: #a0a0a0;
        }
      }
    }
  }

  .active-thumbnail-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    aspect-ratio: 4/3;

    /* width: calc(35vw - 20px - 20px); */
    width: 35vw;
    z-index: 0;

    & .gatsby-image-wrapper {
      width: 100%;
      height: 100%;

      & img {
        object-fit: contain !important;
      }
    }

    @media (max-width: 700px) {
      width: 100%;
      padding: 0 30px;
    }
  }

  .first-gallery-image {
    & img {
      cursor: pointer;
    }
  }

  .works-images-container {
    .works-images-grid {
      &.grid-layout-1 {
        & .works-images {
          margin: 0 0 20px 0;

          &:last-of-type {
            margin: 0;
          }
        }
      }

      &.grid-layout-4 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      }

      &:hover {
        & img {
          opacity: 0.2 !important;
        }
      }

      & img {
        cursor: pointer;
        transform: 300ms opacity ease;

        &:hover {
          opacity: 1 !important;
        }
      }

      @media (max-width: 700px) {
        &.grid-layout-4 {
          display: block;
          grid-template-columns: unset;
          grid-gap: unset;
        }

        &:hover {
          & img {
            opacity: 1 !important;
          }
        }

        & .works-images {
          margin: 0 0 20px 0;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  .page-bottom-border {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    height: 40px;
    transform: 300ms opacity ease;
    background-color: #fff;

    z-index: 10;

    & .border {
      width: max(35vw, 600px);

      border-top: 1px solid #000;

      margin: 0 auto;
    }

    @media (max-width: 700px) {
      height: 30px;

      & .border {
        width: calc(100% - 60px);
      }
    }
  }

  & button.back-button.mobile,
  & a.back-button.mobile {
    display: none;
  }

  @media (max-width: 700px) {
    padding: 0 30px;

    .text-container,
    .single-text-container,
    .single-text-with-links-container {
      & h2 {
        margin: 0 0 1em 0;
      }
    }

    & button.back-button.mobile,
    a.back-button.mobile {
      display: block;
      margin: 0 0 1em 0;

      transition: 300ms color ease;
      color: #a0a0a0;

      &:hover {
        color: #000;
      }
    }
  }
`;

export const Page = ({ children, height }) => {
  return <PageContainer height={height}>{children}</PageContainer>;
};
