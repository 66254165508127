import React, { useEffect, useState } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import { useSessionStorage } from "react-use";
import isbot from "isbot";
import { motion, AnimatePresence } from "framer-motion";

// Get Urls correct
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../link-resolver/linkResolver";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
// import Transition from "../containers/transition";
import { Splash } from "./splash";
import { DefaultSEO } from "./default-seo";

const Container = styled.div``;

const Layout = ({ children }) => {
  // Splash
  const [showSplash, setShowSplash] = useState(true);
  const [removeSplash, setRemoveSplash] = useSessionStorage(
    "removeSplash",
    false
  );

  useEffect(() => {
    const slideUpSplashTimer = setTimeout(() => {
      setShowSplash(false);
      const removeSplashTimer = setTimeout(() => {
        setRemoveSplash(true);
      }, 500);
      return () => clearTimeout(removeSplashTimer);
    }, 5000);
    return () => clearTimeout(slideUpSplashTimer);
  }, []);

  useEffect(() => {
    if (showSplash === false) {
      const removeSplashTimer = setTimeout(() => {
        setRemoveSplash(true);
      }, 500);
      return () => clearTimeout(removeSplashTimer);
    }
  }, [showSplash]);

  const [isUserBot, setIsUserBot] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsUserBot(isbot(navigator.userAgent));
    }
  }, []);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  const duration = 0.35;

  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: duration,
        delay: duration,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: duration },
    },
  };

  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <Location>
        {({ location }) => {
          return (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                key={location.pathname}
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
              >
                <Container location={location.pathname}>
                  <Normalize />
                  <GlobalStyles />
                  <DefaultSEO />

                  {!isUserBot && (
                    <>
                      {!removeSplash && (
                        <Splash
                          showSplash={showSplash}
                          setShowSplash={setShowSplash}
                        />
                      )}
                    </>
                  )}

                  <App location={location.pathname} children={children} />
                </Container>
              </motion.div>
            </AnimatePresence>
          );
        }}
      </Location>
    </PrismicProvider>
  );
};

export default Layout;
