import React, { useRef, useState, useEffect } from "react";
import { graphql, Link, navigate } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { use100vh } from "react-div-100vh";
import { useWindowSize } from "react-use";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const WorkContainer = styled.div`
  padding: 41px 0 39px 0;
  height: calc(
    ${(props) => props.height}px - ${(props) => props.captionHeight}px
  );

  & .image-container {
    position: relative;
    width: 100%;
    height: calc(
      ${(props) => props.height}px - ${(props) => props.captionHeight}px - 60px -
        20px
    );

    overflow: hidden;
    padding: 0 40px;

    & .link {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      z-index: 10;

      &.previous {
        left: 0;

        cursor: url("/icons/previous@1x.png") 0 0,
          url("/icons/previous.cur") 0 0, w-resize !important;
        cursor: -webkit-image-set(
              url("/icons/previous@1x.png") 1x,
              url("/icons/previous@2x.png") 2x
            )
            0 0,
          w-resize !important;
      }

      &.next {
        right: 0;

        cursor: url("/icons/next@1x.png") 0 0, url("/icons/next.cur") 0 0,
          e-resize !important;
        cursor: -webkit-image-set(
              url("/icons/next@1x.png") 1x,
              url("/icons/next@2x.png") 2x
            )
            0 0,
          e-resize !important;
      }
    }

    /* & button,
    & a {
      display: block;

      position: relative;
      width: 100%;

      height: calc(
        ${(props) => props.height}px - ${(props) => props.captionHeight}px -
          60px - 20px
      );

      cursor: url("/icons/close@1x.png") 0 0, url("/icons/close.cur") 0 0, auto !important;
      cursor: -webkit-image-set(
            url("/icons/close@1x.png") 1x,
            url("/icons/close@2x.png") 2x
          )
          0 0,
        auto !important;
    } */
  }

  & .gatsby-image-wrapper {
    width: 100%;
    height: calc(
      ${(props) => props.height}px - ${(props) => props.captionHeight}px - 60px -
        20px
    );

    & img {
      width: 100%;
      height: calc(
        ${(props) => props.height}px - ${(props) => props.captionHeight}px -
          60px - 20px
      );
      object-fit: contain !important;
    }
  }

  @media (max-width: 700px) {
    /* padding: ${(props) => props.captionHeight}px 0 0 0; */

    height: calc(
      ${(props) => props.height}px - ${(props) => props.captionHeight}px
    );

    & .image-container {
      padding: 0;
    }
  }
`;

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;

  padding: 0 30px 22px 30px;

  h1,
  p,
  a,
  button {
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.15px;

    margin: 0 5px;
  }

  h1,
  p {
    text-align: center;
  }

  a,
  button {
    transition: 300ms color ease;
    color: #a0a0a0;

    &:hover {
      color: #000;
    }
  }

  // 700px media query
  @media (max-width: 700px) {
    min-height: 80px;

    h1,
    p {
      width: 100%;
    }

    h1,
    p,
    a,
    button {
      font-size: 15px;
    }

    & .medium-dimensions {
      display: block;
    }
  }
`;

const Work = ({ data, location, pageContext }) => {
  const windowHeight = use100vh();
  const ref = useRef(null);
  const [backButton, setBackButton] = useState(null);

  const { width } = useWindowSize();

  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, [ref, width]);

  useEffect(() => {
    if (location !== undefined && location !== null) {
      if (location.state !== undefined && location.state !== null) {
        if (
          location.state.section !== undefined &&
          location.state.section !== null
        ) {
          setBackButton(location.state.section);
        }
      }
    }
  }, [location, setBackButton]);

  return (
    <>
      <PageSeo
        seoTitle={data.prismicWork.data.title_of_work.text}
        seoImage={data.prismicWork.data.image.url}
        seoText={null}
      />

      <WorkContainer height={windowHeight} captionHeight={height}>
        <div className="image-container">
          <Link
            className="previous link"
            to={pageContext.previousWork.url}
            state={{ section: backButton }}
          />
          <GatsbyImage
            image={data.prismicWork.data.image.gatsbyImageData}
            alt={data.prismicWork.data.image.alt}
          />
          <Link
            className="next link"
            to={pageContext.nextWork.url}
            state={{ section: backButton }}
          />
        </div>
      </WorkContainer>

      <CaptionContainer ref={ref}>
        <h1>
          <em>{data.prismicWork.data.title_of_work.text}</em>,{" "}
          {data.prismicWork.data.date}.{" "}
          <span className="medium-dimensions">
            {data.prismicWork.data.medium}
            {data.prismicWork.data.dimensions !== null
              ? `, ${data.prismicWork.data.dimensions}`
              : ``}
            {data.prismicWork.data.dimensions_inches !== null
              ? `, ${data.prismicWork.data.dimensions_inches}`
              : ``}
            {data.prismicWork.data.framed_dimensions !== null
              ? `, ${data.prismicWork.data.framed_dimensions}`
              : ``}
          </span>
        </h1>

        <Link
          to={`/`}
          className="back-button"
          state={{ section: data.prismicWork.id }}
        >
          Close
        </Link>
      </CaptionContainer>
    </>
  );
};

export default withPrismicPreview(Work);

export const query = graphql`
  query Work($id: String) {
    prismicWork(id: { eq: $id }) {
      _previewable
      url
      id
      data {
        title_of_work {
          text
        }
        dimensions
        dimensions_inches
        framed_dimensions
        date
        medium
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            breakpoints: [1000, 1500, 2000, 3000, 4000]
          )
          alt
        }
      }
    }
  }
`;
