import React, { useState, useEffect } from "react";
import { graphql, Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { zeroRightClassName } from "react-remove-scroll-bar";

// Layout
import { Page } from "../components/containers/page";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Components
import { Gallery } from "../components/images/gallery";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

Modal.setAppElement("#___gatsby");

const Exhibition = ({ data, location }) => {
  const [activeModalId, setActiveModalId] = useState(null);
  const [defaultIndex, setDefaultIndex] = useState(0);

  const [backButton, setBackButton] = useState(null);

  useEffect(() => {
    if (location !== undefined && location !== null) {
      if (location.state !== undefined && location.state !== null) {
        if (
          location.state.section !== undefined &&
          location.state.section !== null
        ) {
          setBackButton(location.state.section);
        }
      }
    }
  }, [location, setBackButton]);

  // Window Height
  const height = use100vh();

  const content = data.prismicExhibition.data.body.map((slice, index) => {
    if (slice.slice_type === `gallery`) {
      const media = slice.items.map((image, index) => {
        return (
          <div
            className="slide"
            key={`single_exhibition_${index}_${image.image.alt}`}
          >
            <GatsbyImage
              image={image.image.gatsbyImageData}
              alt={image.image.alt}
            />
          </div>
        );
      });

      const captions = slice.items
        .filter((caption) => caption.caption !== null)
        .map((caption, index) => caption.caption.richText);

      return (
        <div
          className="gallery-container module"
          key={`single_gallery_container_${index}_${slice.id}`}
        >
          <h2 className="section-title">Gallery</h2>

          <div
            className="first-gallery-image"
            onClick={() => setActiveModalId(slice.id)}
          >
            {media[0]}
          </div>

          {slice.id === activeModalId && (
            <Modal
              isOpen={slice.id === activeModalId}
              portalClassName={`ReactModalPortal-artworks-lightbox`}
              className={`lightbox-modal ${zeroRightClassName}`}
              contentLabel={`Artworks Lightbox`}
              shouldFocusAfterRender={false}
            >
              <Gallery
                media={media}
                windowHeight={height}
                type={`gallery`}
                setActiveModalId={setActiveModalId}
                defaultIndex={0}
                setDefaultIndex={setDefaultIndex}
                captions={captions}
              />
            </Modal>
          )}
        </div>
      );
    }

    if (slice.slice_type === `text`) {
      return (
        <div
          key={`single_exhibition_${index}_${slice.id}`}
          className="text-container overview-grid module"
        >
          <div className="column left">
            <PrismicRichText field={slice.primary.section_title.richText} />

            {backButton !== null ? (
              <button
                onClick={() => navigate(-1)}
                className="back-button desktop"
              >
                Back
              </button>
            ) : (
              <Link to={`/exhibitions/`} className="back-button desktop">
                Back
              </Link>
            )}
          </div>

          <div className="column right">
            <div className="header-text-container">
              <p>{data.prismicExhibition.data.forthcoming}</p>
              <PrismicRichText
                field={data.prismicExhibition.data.title_of_show.richText}
                components={{
                  heading1: ({ children }) => (
                    <h1 className="italic">{children}</h1>
                  ),
                }}
              />
              <p className="small-caps">
                {data.prismicExhibition.data.solo_or_group_show_text}
              </p>
              <p>{data.prismicExhibition.data.date_full}</p>
              <p>{data.prismicExhibition.data.location}</p>
            </div>

            <div className="body-text-container">
              <PrismicRichText field={slice.primary.exhibition_text.richText} />
            </div>
          </div>
        </div>
      );
    }
    if (slice.slice_type === `works_images`) {
      const media = slice.items.map((image, index) => {
        return (
          <div
            className={`works-images`}
            key={`single_exhibition_works_image_${index}_${image.image.alt}`}
            onClick={() => {
              setActiveModalId(slice.id);
              setDefaultIndex(index);
            }}
          >
            <GatsbyImage
              image={image.image.gatsbyImageData}
              alt={image.image.alt}
            />
          </div>
        );
      });

      const captions = slice.items.map(
        (caption, index) => caption.caption.richText
      );

      return (
        <div
          className="works-images-container module"
          key={`single_work_images_module_${index}_${slice.id}`}
        >
          <h2 className="section-title">Works</h2>
          <div
            className={`works-images-grid grid-layout-${
              media.length >= 4 ? `4` : `1`
            }`}
            key={`single_exhibition_${index}_${slice.id}`}
          >
            {media}
          </div>

          {slice.id === activeModalId && (
            <Modal
              isOpen={slice.id === activeModalId}
              portalClassName={`ReactModalPortal-artworks-lightbox`}
              className={`lightbox-modal ${zeroRightClassName}`}
              contentLabel={`Artworks Lightbox`}
              shouldFocusAfterRender={false}
            >
              <Gallery
                media={media}
                windowHeight={height}
                type={`works`}
                setActiveModalId={setActiveModalId}
                defaultIndex={defaultIndex}
                setDefaultIndex={setDefaultIndex}
                captions={captions}
              />
            </Modal>
          )}
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={data.prismicExhibition.data.title_of_show.text}
        seoImage={null}
        seoText={null}
      />

      <Page>
        <div className="page-grid">
          <div className="spacer" />

          <div className="content-container">
            {content}

            {backButton !== null ? (
              <button
                onClick={() => navigate(-1)}
                className="back-button mobile"
              >
                Back
              </button>
            ) : (
              <Link to={`/exhibitions/`} className="back-button mobile">
                Back
              </Link>
            )}

            <div className="bottom-border" />
          </div>

          <div className="spacer" />
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Exhibition);

export const query = graphql`
  query Exhibition($id: String) {
    prismicExhibition(id: { eq: $id }) {
      _previewable
      url
      data {
        title_of_show {
          richText
          text
        }
        solo_or_group_show_text
        location
        forthcoming
        date_year
        date_full
        body {
          ... on PrismicExhibitionDataBodyGallery {
            id
            slice_type
            items {
              image {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  breakpoints: [1000, 1500, 2000, 3000, 4000]
                )
                alt
              }
              caption {
                richText
              }
            }
          }
          ... on PrismicExhibitionDataBodyText {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              exhibition_text {
                richText
              }
            }
          }
          ... on PrismicExhibitionDataBodyWorksImages {
            id
            slice_type
            items {
              image {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  breakpoints: [1000, 1500, 2000, 3000, 4000]
                )
                alt
              }
              caption {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
