import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.wf-loading {
  body {
    opacity: 0;
    transition: 300ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

html {
  scroll-behavior: smooth;
  overscroll-behavior-block: contain;
  overflow-y: scroll;
}

body {
  font-family: "WilliamRegular", serif;
  font-weight: normal;
  font-style: normal;

  font-size: 17px;
  line-height: 135%;
  letter-spacing: 0.15px;
  font-variant-ligatures: none;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;


  &.hover-active {
    & header,
    & .list-item,
    & .page-bottom-border {
      opacity: 0.2;
    }
  }

  @media (max-width: 700px) {
    font-size: 15px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-size: 17px;
  line-height: 135%;
  letter-spacing: 0.15px;

  font-family: "WilliamRegular", serif;
  font-weight: normal;

  margin: 0;

  @media (max-width: 700px) {
    font-size: 15px;
  }
}

em,
.italic {
  font-family: "WilliamRegularItalic", serif;
}

.small-caps{
  font-feature-settings: "smcp";
  text-transform: lowercase;
  letter-spacing: 0.1px;
}

/*--------------------------------------------------------------
  ## Close button
  --------------------------------------------------------------*/

.close-text-button{
  cursor: none;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    appearance: none;
    color: inherit;

    margin:0;
    padding:0;
    border:0;

    cursor:pointer;
    text-align: left;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 12px 0;
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Image Scaling
  --------------------------------------------------------------*/

.gatsby-image-wrapper {
  width: 100%;
  height: 100%;
}


/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-lightbox,
.ReactModalPortal-splash,
.ReactModalPortal-artworks-lightbox {
  position: fixed !important;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.lightbox-modal{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: #fff !important;

  @media (max-width: 700px) {
    background-color: rgb(255, 255, 255) !important;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-splash .ReactModal__Overlay,
.ReactModalPortal-lightbox .ReactModal__Overlay {
  background-color: transparent !important;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;

  inset: unset !important;
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

`;

export default GlobalStyle;
